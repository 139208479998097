import { atom, selector, selectorFamily } from 'recoil';
import {
  Breadcrumb,
  CallToAction,
  FeatureIcon,
  Link,
  MediaContent,
  Variant,
  ColourOption,
  SubNavigationItem,
} from '~/common/models';
import { IReviewsProps } from '~/common/models/interfaces/reviews';

export interface ModelLanding {
  url: string;
  headerBanner: HeaderBanner;
  reviews: IReviewsProps;
}

export interface HeaderBanner {
  title?: string;
  bannerImage: string;
  mediaContent?: MediaContent[];
  features: FeatureIcon[];
  variantName?: string;
  disclaimer?: string;
}

export interface CompareRangeModel {
  modelId?: string;
  modelName?: string;
  modelBadgeUrl?: string;
  modelPimIdentifier?: string;
  modelImageSrc?: string;
  modelVariants?: CompareRangeVariant[];
  pimModelVariants?: PimModelVariant[];
  brochureUrl?: string;
  leadInHeading?: string;
  leadInHeadingDisclaimer?: string;
  hideCompareRangeButton?: boolean;
}

export interface PimModelVariant {
  modelPimIdentifier: string;
  variantPimIdentifier: string;
}

export interface CompareRangeVariant extends Variant {
  isHighlight?: boolean;
  selectedColourCode?: string;
  introText?: string;
  offersUrl?: Link;
  learnMore?: CallToAction;
  buildYourOwn?: CallToAction;
  viewSpecsAndFeat?: CallToAction;
  brochureDownload?: CallToAction;
  compareOtherSpotlight?: boolean;
  colourDisclaimer?: string;
  keyFeatureDisclaimer?: string;
}

export interface CompareOtherModels {
  heading?: string;
  subHeading?: string;
  isVisible?: boolean;
  models?: CompareRangeModel[];
}

interface CompareRangePopupBase {
  id?: string;
  name?: string;
  pimIdentifier?: string;
  imageSrc?: string;
  displayOrder?: number;
  legacyId?: string;
}

export interface CompareRangePopupVariant extends CompareRangePopupBase {
  modelPimIdentifier?: string;
  _selected?: boolean;
  _order?: number;
}

export interface CompareRangePopupModel extends CompareRangePopupBase {
  variants: CompareRangePopupVariant[];
  _active?: boolean;
}

export interface SecondaryNav {
  secondaryNavTitle: string;
  items: SubNavigationItem[];
}

export const HeaderBannerStore = atom<ModelLanding>({
  key: 'ModelLanding',
  default: {
    url: '',
    headerBanner: {
      bannerImage: '',
      features: [],
    },
    reviews: {
      heading: '',
      reviewList: [],
    },
  },
});

export const ModelPageUrl = selector({
  key: 'ModelPageUrl',
  get: ({ get }) => get(HeaderBannerStore).url,
});

export const headerFeaturesState = selector<FeatureIcon[]>({
  key: 'HeaderFeature',
  get: ({ get }) => get(HeaderBannerStore).headerBanner.features,
});

export const ColourOptionsQuery = selectorFamily<ColourOption[], string>({
  key: 'VehicleVariantColourOptions',
  get: (variantId) => async () => {
    const res = await fetch(`/api/vehicles/${variantId}/colour-options`);
    const colours = (await res.json()) as ColourOption[];
    return colours;
  },
});

export const CompareRangeModelState = atom<CompareRangeModel>({
  key: 'CompareRangeModel',
  default: {},
});

export const VehicleModelName = selector<string | undefined>({
  key: 'VehicleModelName',
  get: ({ get }) => get(CompareRangeModelState).modelName,
});

export const CompareRangeVariants = selector({
  key: 'CompareRangeVariants',
  get: ({ get }) => get(CompareRangeModelState).modelVariants ?? [],
});

export const CompareOtherModelState = atom<CompareOtherModels>({
  key: 'CompareRangeOtherModels',
  default: {},
});

export const CompareRangePopupModelState = atom<CompareRangePopupModel[]>({
  key: 'CompareRangePopupModel',
  default: [],
});

export const BreadcrumbState = atom<Breadcrumb[]>({
  key: 'ModelBreadcrumbs',
  default: [],
});

export const SecondaryNavState = atom<SecondaryNav>({
  key: 'SubNavigation',
  default: {
    secondaryNavTitle: '',
    items: [],
  },
});
