import React from 'react';
import loadable from '@loadable/component';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { Loading } from '~/common/components/ui-elements';
import { IReviewsProps } from '~/common/models/interfaces/reviews';
import { Breadcrumb, SubNavigationItem } from '~/common/models';
import {
  CompareRangeModelState,
  CompareOtherModelState,
  CompareOtherModels,
  CompareRangeModel,
  HeaderBanner,
  HeaderBannerStore,
  SecondaryNavState,
  BreadcrumbState,
  CompareRangePopupModel,
  CompareRangePopupModelState,
} from './store';
import {
  ModelColorPickerState,
  ModelColourPicker,
} from '~/macros/model-colour-picker/store';

const ModelLandingPage = loadable(
  () => import('./components/model-landing-page'),
  {
    resolveComponent: (component) => component.ModelLandingPage,
    fallback: <Loading height="100vh" />,
  }
);

interface RootComponentProps {
  context: any;
  location: string;
  url: string;
  tempalteAlias: string;
  headerBanner: HeaderBanner;
  compareRangeModel: CompareRangeModel;
  compareOtherModels: CompareOtherModels;
  compareRangePopupModels: CompareRangePopupModel[];
  subNavigations: SubNavigationItem[];
  secondaryNavTitle: string;
  reviews: IReviewsProps;
  breadcrumbs: Breadcrumb[];
  modelColourPicker: ModelColourPicker;
}

export const RootComponent = (props: RootComponentProps) => {
  const initialize = ({ set }: MutableSnapshot) => {
    set(CompareRangeModelState, props.compareRangeModel);
    set(CompareOtherModelState, props.compareOtherModels);
    set(
      CompareRangePopupModelState,
      props.compareRangePopupModels.map((m) => ({
        ...m,
        _active: false,
        variants: m.variants?.map((v) => ({
          ...v,
          _order: Number.MAX_SAFE_INTEGER,
          _selected: false,
        })),
      }))
    );
    set(BreadcrumbState, props.breadcrumbs);
    set(SecondaryNavState, {
      secondaryNavTitle: props.secondaryNavTitle,
      items: props.subNavigations,
    });

    set(HeaderBannerStore, {
      url: props.url,
      headerBanner: props.headerBanner,
      reviews: props.reviews,
    });

    set(ModelColorPickerState, props.modelColourPicker);
  };

  return (
    <RecoilRoot initializeState={initialize}>
      <ModelLandingPage
        tempalteAlias={props.tempalteAlias}
        modelName={props.compareRangeModel.modelName}
      />
    </RecoilRoot>
  );
};
